import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import RingpoemsIntro from './ringpoemsIntro'

// markup
const Posies_g = () => {
  return (
   <Layout >
      <Head title='Ring Poems (Posies) in English - G-L'/>
     
     <RingpoemsIntro />
     <h2>G - L</h2>
     <br/>
GOD HATH ME SENT MY <br/>
HART'S CONTENT &nbsp;<br/>
<br/>
GOD OUR LOVE CONTINUE EVER <br/>
THAT WE IN HEAVEN MAY LIVE TOGETHER &nbsp;<br/>
<br/>
GOD SAW THEE MOST FIT FOR ME &nbsp;<br/>
<br/>
GOD SEND HER ME MY WIFE TO BE &nbsp;<br/>
<br/>
GOD Yt HATH KEPT THY <br/>
HEART FOR MEE <br/>
GRANT THAT OUR LOVE MAY <br/>
FAITHFULL BEE &nbsp;<br/>
<br/>
GOD'S DIRECTIONS JOYNED OUR AFFECTIONS &nbsp;<br/>
<br/>
GOD'S GIFT THOU ART MY <br/>
DEAREST HEART &nbsp;<br/>
<br/>
GOD'S INTENT NONE CAN PREVENT &nbsp;<br/>
<br/>
GOD'S WILL IS DONE AND I HAVE MINE <br/>
MY HEART'S AT REST IN HAVING THINE &nbsp;<br/>
<br/>
GREAT DUNDEE FOR GOD AND ME<br/>
<br/>
GREAT IS THE GRIEF THAT ISUSTAIN <br/>
WHICH HERE IS FIGURED BY A FLAME <br/>
THAT DOTH TORMENT ME IN EACH PART <br/>
BUT CHIEFLY SEIZETH ON MY HEART <br/>
YET RATHER THAN MY HEART SHALL TURN <br/>
FROM MY FAITH IN LOVE I'LE BURN &nbsp;<br/>
<br/>
GREAT JOYE IN THEE CONTINUALLY &nbsp;<br/>
<br/>
GRIEVE NOT HIS HEART WHOSE JOY THOU ART <br/>
<br/>
HAD I NOT SPOKE MY HEART HAD BROKE <br/>
THE UTMOST SCOPE OF LOVE IS HOPE &nbsp;<br/>
<br/>
HAND HEART AND ALL IHAVE IS THINE <br/>
<br/>
HAND HEART AND ALL THOU HAST BE MINE &nbsp;<br/>
<br/>
HAPPY IN THEE HAS GOD MADE ME &nbsp;<br/>
<br/>
HEARTS CONTENT CAN NERE REPENT <br/>
<br/>
HEARTS TRULY TIED NONE CAN DIVIDE &nbsp;<br/>
<br/>
I AM A PORE MAIDEN <br/>
AND FAINE WOULD MARRY <br/>
AND THE LACKE OF GOODS IS <br/>
THE CAUSE THAT I TARRY &nbsp;<br/>
<br/>
I AM YOUR FRIEND UNTO THE END &nbsp;<br/>
<br/>
I AM YOUR LOTT REFUSE ME NOT<br/>
<br/>
I AM YURES WHIL LYFE ENDURES &nbsp;<br/>
<br/>
I BID ADIEU TO ALL BUT YOU &nbsp;<br/>
<br/>
I CANNOT SHOW THE LOVE OWE &nbsp;<br/>
<br/>
I DID COMMIT NO ACT OF FOLLY <br/>
WHEN I MARRIED MY SWEET MOLLY &nbsp;<br/>
<br/>
I DO OWE BOTH LOVE AND DUTY <br/>
TO YOUR VERTUE AND YOUR BEAUTY &nbsp;<br/>
<br/>
I DO NOT REPENT THAT I <br/>
GAVE MY CONSENT &nbsp;<br/>
<br/>
I DO REJOYCE IN THEE MY CHOYCE &nbsp;<br/>
<br/>
I FANCY NONE BUT THEE ALONE &nbsp;<br/>
<br/>
I GIVE IT THEE MY LOVE TO BE &nbsp;<br/>
<br/>
I GIVE IT THEE TO THINK ON ME &nbsp;<br/>
<br/>
I HAVE OBTAINED WHOME GOD ORDAINED &nbsp;<br/>
<br/>
I JOY IN THEE JOY THOU IN ME &nbsp;<br/>
<br/>
I JOY TO FIND A CONSTANT MIND &nbsp;<br/>
<br/>
I LIKE I LOVE AS TURTLE DOVE &nbsp;<br/>
<br/>
I LIKE I LOVE I LIVE CONTENT <br/>
<br/>
I MADE MY CHOICE NOT TO REPENT &nbsp;<br/>
<br/>
I LIKE MY CHOICE AND DO REJOYCE &nbsp;<br/>
<br/>
I LIVE AND DY IN LOYALTYE &nbsp;<br/>
<br/>
I LIVE IF AYE IF NO I DIE &nbsp;<br/>
<br/>
I SEEK TO BE NOT THINE BUT THEE &nbsp;<br/>
<br/>
I SHALL IN THEE MOST HAPPY BE &nbsp;<br/>
<br/>
I THAT CUPID CALLED AM <br/>
AND SHALL NEVER BE A MAN <br/>
BUT AM STILL THE BLINDED BOY <br/>
THAT BREEDS LOVERS MUCH ANOY &nbsp;<br/>
<br/>
<br/>
HAVING GOTTEN ON A DAY <br/>
FROM MY MOTHER LEAVE TO PLAY <br/>
AND OBTAINED USE OF SIGHT <br/>
I IN WANTONNESE DID WRITE <br/>
THESE SAME POSIES WHICH I SEND <br/>
AND TO LOVERS DO COMMEND <br/>
WHICH IF THE Y BE WRIT WITHIN <br/>
THE LITTLE CIRCLE OF A RING <br/>
OR BE SENT UNTO YOUR LOVES <br/>
WITH FINE HANDKERCHERS AND GLOVES <br/>
I DO KNOW THAT LIKE MY DART <br/>
THEY HAVE POWER TO WOUND THE HEART <br/>
FOR INSTEAD OF FLOWERS AND ROSES <br/>
HERE ARE WORDS BOUND UP IN POSIES &nbsp;<br/>
<br/>
<br/>
I WILL BE TRUE ALWAYS TO YOU &nbsp;<br/>
<br/>
I WILL REMAIN ALWAYS THE SAME &nbsp;<br/>
<br/>
WILL YOU TREWELIE SERVE &nbsp;<br/>
<br/>
I WISH THAT WE TWO WERE A PAIR <br/>
AS THESE HAPPY GLOVES HERE ARE &nbsp;<br/>
<br/>
I WISH TO THEE ALL JOIE MAY BEE &nbsp;<br/>
<br/>
IF ALL MANKIND <br/>
WOULD LIVE IN LOVE <br/>
THIS WORLD WOULD MUCH <br/>
RESEMBLE THAT ABOVE &nbsp;<br/>
<br/>
IF I SURVIVE I WILL HAVE FIVE &nbsp;<br/>
<br/>
IF I THINK MY WIFE IS FAIR <br/>
WHAT NEED OTHER PEOPLE CARE &nbsp;<br/>
<br/>
IF IN THY LOVE THOU CONSTANT BEE <br/>
MY HEART SHALL NEVER PART FROM THEE &nbsp;<br/>
<br/>
IF YOU DENY I WISH TO DIE &nbsp;<br/>
<br/>
IF YOU DENY THEN SURE I DYE &nbsp;<br/>
<br/>
IF YOU LOVE ME AS I LOVE YOU <br/>
NOTHING CAN CUT OUR LOVE IN TWO &nbsp;<br/>
<br/>
IN CONSTANCIE I LIVE AND DYE &nbsp;<br/>
<br/>
IN GOD AND THEE ALL COMFORT BE &nbsp;<br/>
<br/>
IN LOVE AT NIGHT IS MY DELIGHT &nbsp;<br/>
<br/>
IN LOVE'S DELIGHT SPEND DAY AND NIGHT &nbsp;<br/>
<br/>
IN THEE A FLAME IN ME THE SAME &nbsp;<br/>
<br/>
IN THEE MY CHOYCE I DOE REJOYCE &nbsp;<br/>
<br/>
IN THY BREAST MY HEART DOTH REST &nbsp;<br/>
<br/>
IN THY SIGHT IS MY DELIGHT &nbsp;<br/>
<br/>
IN TRUST BE JUSTE &nbsp;<br/>
<br/>
IN UNITIE LET'S LIVE AND DY &nbsp;<br/>
<br/>
IN WEAL AND WOE MY LOVE I'LE SHOW &nbsp;<br/>
<br/>
INNUMERABLE ARE THE STARS I SEE <br/>
BUT IN MY HEART NO STAR LIKE THEE &nbsp;<br/>
<br/>
<br/>
IT IS A PITTY YOU SHOULD WEAR A MASK <br/>
THIS IS THE REASON IF YOU DO ASK <br/>
BECAUSE IT HIDES YOUR FACE SO FAIR <br/>
WHERE ROSES MIXT WITH LILLIES ARE <br/>
IT CLOUTS YOUR BEAUTY SO THAT WE <br/>
YOUR CHERRY LIPS CAN SELDOM SEE <br/>
AND FROM YOUR FACE KEEPS OFF OUR EYES <br/>
WHICH IS INDEED LOVES PARADICE &nbsp;<br/>
<br/>
JOY AND CARE LET US TAKE SHARE &nbsp;<br/>
<br/>
JOY DOTH ABOUND WHERE LOVE IS FOUND &nbsp;<br/>
<br/>
JOY IN NONE BUT YOU ALONE <br/>
<br/>
JOYE DAY AND NIGHT BEE OUR DELIGHT <br/>
<br/>
KEEPE FAYTH TILL DETH &nbsp;<br/>
<br/>
KINDLY TAKE THIS GIFT OF MINE <br/>
FOR GIFT AND GIVER BOTH ARE THINE &nbsp;<br/>
<br/>
KNOTTS OF LOVE ARE KNITT ABOVE &nbsp;<br/>
<br/>
LET FRIEND NOR FOE THIS SECRET KNOW &nbsp;<br/>
<br/>
LET LYKINGE LASTE &nbsp;<br/>
<br/>
LET LOVE ABIDE TILL DEATH DIVIDE &nbsp;<br/>
<br/>
LET LOVE DIVINE OUR HEARTS ENTWINE &nbsp;<br/>
<br/>
LET MEE IN THEE MOST HAPPY BEE &nbsp;<br/>
<br/>
LET ME SERVE TILL I DESERVE <br/>
<br/>
LET ME WITH THEE FUL HAPPY BE &nbsp;<br/>
<br/>
LET OUR CONTEST BEE WHO LOVES BEST &nbsp;<br/>
<br/>
LET US CONTEST WHICH SHALL LOVE BEST &nbsp;<br/>
<br/>
LET US LOVE LIKE TURTLE DOVE &nbsp;<br/>
<br/>
LET US SHARE IN JOY AND CARE &nbsp;<br/>
<br/>
LIKE PHILIS THERE IS NONE <br/>
SHE TRUELY LOVES HER CHORIDON &nbsp;<br/>
<br/>
LIVE AS I OR ELSE I DYE &nbsp;<br/>
<br/>
LOVE AND JOYE CAN NEVER CLOYE &nbsp;<br/>
<br/>
LOVE AND PRAY NIGHT AND DAYE &nbsp;<br/>
<br/>
LOVE AND RESPECT I DOE EXPECT &nbsp;<br/>
<br/>
LOVE AND WINE IN THIS DEGREE <br/>
THE ELDER BETTER STILL THEY BE <br/>
SO OUR LONG SUIT THEN SHALL BE TRUE <br/>
CHANG NOT THY OLD LOVE FOR A NEW &nbsp;<br/>
<br/>
LOVE AS I OR ELCE I DIE &nbsp;<br/>
<br/>
LOVE EVER OR LOVE NEVER &nbsp;<br/>
<br/>
LOVE HIM IN HEART WHOSE JOY THOU ART &nbsp;<br/>
<br/>
LOVE I HAVE YET LOVE I CRAVE &nbsp;<br/>
<br/>
LOVE I LIKE THEE SWEET REQUITE ME &nbsp;<br/>
<br/>
LOVE INTIRE IS MY DESIRE &nbsp;<br/>
<br/>
LOVE IS A CHAIN <br/>
WHOSE LINKES OF GOLD <br/>
TWO HEARTS WITHIN <br/>
ONE BOSOM HOLD &nbsp;<br/>
<br/>
<br/>
LOVE IS SURE WHERE FAITH IS PURE &nbsp;<br/>
<br/>
LOVE ME TRUE AS I DO YOU <br/>
<br/>
LVEE NEVER DYES WHERE VERTUE LYES &nbsp;<br/>
<br/>
LOVES DELIGHT IS TO UNITE <br/>
I NOW DO SUE FOR LOVE TO YOU <br/>
<br/>
LUST LOVES TO RANGE LOVE KNOWS NO CHANGE &nbsp;<br/>
<br/>
<hr/>
   </Layout>
  )
}

export default Posies_g
